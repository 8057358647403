<template>
  <div class="main-content">
    <div class="Block-Area">
      <div>ブロックされている人数:{{ block_ids.length }}</div>
      <div>
        <ul>
          <li v-for="userid in block_ids" v-bind:key="userid">
            ID:<a @click="profile(userid)">{{ userid }}</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: function() {
    return {
      block_ids: null,
    };
  },
  created: function() {
    const config = {
      headers: {
        authorization: "Bearer " + this.$store.getters.userToken,
      },
    };
    this.axios
      .get(
        "https://cors.bexix.net/https://api-057.yay.space/v1/users/block_ids/",
        config
      )
      .then((response) => {
        this.block_ids = response.data.block_ids;
      })
      .catch(() => {
        this.$router.push({
          name: "NotFound",
        });
      });
  },
  methods: {
    profile(userid) {
      let resolvedRoute = this.$router.resolve({
        name: "Profile",
        query: { id: [userid] },
      });
      window.open(resolvedRoute.href, "_blank");
    },
  },
};
</script>

<style>
.main-content {
  margin: 0 auto;
}

.Block-Area {
  text-align: center;
}

.Block-Area li {
  list-style: none;
}
</style>
